<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-card outlined color="transparent" align="center">
          <div
            class="d-flex justify-center align-center flex-row"
            id="signupHeader"
          >
            <span class="brand-logo">
              <v-icon x-large>mdi-water-circle</v-icon>
            </span>
            <span class="d-flex flex-column brand_main">
              <span>Celestine</span>
              <span>Signup</span>
            </span>
          </div>

          <v-form class="mt-10">
            <v-text-field
              label="E-mail"
              :rules="emailRules"
              v-model="inputs.email"
              type="email"
              outlined
            />

            <v-text-field
              label="Username"
              v-model="inputs.username"
              :rules="usernameRules"
              outlined
            />
            <v-text-field
              label="Password"
              v-model="inputs.password"
              :rules="passwordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              outlined
            />

            <v-text-field
              label="Confirm Password"
              :rules="confirmPasswordRules"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              outlined
            />
          </v-form>

          <v-btn color="#286aff" block @click="signup()"> Signup </v-btn>

          <v-btn @click="$router.push('/auth/signin')" class="mt-2" text>
            Sign in existing account
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Signup",

  methods: {
    async signup() {
      const data = {
        email: this.inputs.email,
        username: this.inputs.username,
        password: this.inputs.password,
        captcha: ""
      };
      const req = await this.$http
        .post("/public/register", data)
        .then((res) => res.data);

      console.log(req);

      if (req.status === false) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Oops!`,
          content: req.message,
        });

        return;
      }

      this.$dtoast.pop({
        preset: "success",
        heading: `Success`,
        content: req.message,
      });

      var sessionStr = {};
      sessionStr.username = this.inputs.username;
      sessionStr.toverify = true;
      localStorage.session = JSON.stringify(sessionStr);

      setTimeout(() => {
        this.$router.push("/auth/verify");
      }, 2000);
    },
  },

  data() {
    return {
      showPassword: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      usernameRules: [
        (v) => !!v || "Username is required",
        (v) => v.length > 6 || "Username must be atleast 6 characters long",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length > 8 || "Password must be atleast 8 characters long",
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v) ||
          "Password should be of atleast one letter and one number",
      ],

      confirmPasswordRules: [
        (v) => v == this.inputs.password || "Passwords doesn't match",
      ],

      inputs: {
        email: "",
        password: "",
        username: "",
      },
    };
  },
};
</script>

<style lang="scss">
.v-card {
  min-width: 300px !important;
}
#signupHeader {
  .brand_main {
    text-align: justify;
    span:first-of-type {
      font-family: "Poppins";
      font-weight: 600;
      font-size: 20px;
    }

    span:last-of-type {
      font-family: "Poppins";
      font-size: 13px;
    }
  }
  .brand-logo {
    margin-right: 10px;
    i {
      font-size: 48px !important;
      color: #286aff !important;
    }
  }
}
</style>
